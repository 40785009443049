.container-cards {
    display: flex;
    flex-direction: row;
    width: 1500px;
    align-items: center;
    justify-content: center;
}

.buttons button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.button-left {
    transform: rotate(-90deg);
}

.button-right {
    transform: rotate(90deg);
}

.container-cards .carousel {
    
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
}


.carousel {
    margin: 10px;
    width: 1170px;
    height: 570px;
}

.carousel::-webkit-scrollbar {
    display: none;
    overflow: -moz-scrollbars-none;
}

.cards-body {
    background: #111111;
    border: 3px solid #000000;
    width: 370px;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    margin: 10px;
    flex: none;
}

.cards-img {
    padding-top: 90px;
}
.cards-body:hover .cards-img {
    padding-top: 35px;
    transition: ease-in-out 0.5s
}

.cards-title {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-top: 50px;
    font-weight: 700;
    font-size: 18px;
    font-size: 1.4em;
}
.cards-body:hover .cards-title{
    padding-top: 15px;
    transition: ease-in-out 0.5s
}

.icon-more {
    transform: rotate(180deg);
    padding-bottom: 30px;
}

.cards-body:hover .icon-more {
    opacity: 0;
    transition: ease-out 0.2s;
}

.cards-description {
    padding-top: 10px;
    opacity: 0;
    margin: 0 15px;
    font-weight: 400;
    font-size: 1.1em;
}
.cards-body:hover .cards-description {
    opacity: 1;  
    transform: translateY(-100px);
    transition: all 0.2s ease-out;
}


@media screen and (max-width: 1300px) {
    
    .container-cards {
        width: 1000px;
    }

    .carousel {
        width: 850px;
        margin: 20px;
    }
    
    .cards-body {
        margin: 20px;
    }
}

@media screen and (max-width: 1020px) {
    .container-cards {
        width: 600px;
    }

    .carousel {
        width: 390px;
        margin: 0 10px;
    }
    
    .cards-body {
        margin: 0 10px;
    }

    .icon-more {
        display: none;
    }

    .cards-img {
        padding-top: 35px;
    }
    .cards-body:hover .cards-img {
        transition: none;
    }
    
    .cards-title {
        padding-top: 65px;
        font-weight: 700;
        font-size: 18px;
        font-size: 1.4em;
        padding-top: 15px;
    }
    .cards-body:hover .cards-title{
        transition: none;
    }

    .cards-description {
        opacity: 1;
        margin: 0 20px;
        font-weight: 400;
        font-size: 1.2em;
        padding-bottom: 60px;
    }
    .cards-body:hover .cards-description {  
        transform: none;
    }
}

@media screen and (max-width: 450px) {
    
    .container-cards {
        width: 415px;
    }
    
    .carousel {
        width: 390px;
        height: 600px;
        margin: 0 5px;
    }
   
    .cards-body {
        margin: 0 10px;
        width: 250px;
        height: 550px;
        justify-content: start;
    }

    .cards-title {
        padding-bottom: 20px;
    }

    .cards-description {
        font-size: 1.1em;
    }

}