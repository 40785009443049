.areas {
    background: #38435A;
}

.areas .container {
    width: 72%;
    padding: 5em 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

p {
    text-align: center;
    margin: 0 40px;
}

@media screen and (max-width: 720px) {
    
    .areas .container {
        width: 100%;
    }

    .areas .container h4 {
        font-size: 1.4em;
        line-height: 1.6em;
    }
    
    p {
        font-size: 1.1em;
        padding: 15px 0;
    }
}