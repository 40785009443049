.NavbarItems {
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-logo {
    justify-self: start;
    margin-right: 20px;
}

img {
    margin: 10px;
    max-width: 180px;
    height: auto;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: start;
    margin-right: 2rem;
} 

.nav-links {
    color: #38435A;
    text-decoration: none;
    padding: 0.9rem 0.5rem;
    text-transform: uppercase;
}

.nav-links:hover {
    background-color: #1c1c1c1c;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: rgb(8, 116, 108);
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 1180px) {

    .NavbarItems {
        position: absolute;
    }
    
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 445px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(10px);
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        color: rgb(8, 116, 108);
        font-size: 1.4em;
    }

    nav.links:hover {
        background-color: #1c1c1c1c;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-45%, 80%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        left: 10px;
        transform: translate(100%, 50%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #2e384d;
        font-size: 2rem;
    }
    
}

@media screen and (max-width: 760px) {
   

    .navbar-logo {
        position: absolute;
        right: -35px;
    }
}

