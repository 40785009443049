* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Titillium Web', sans-serif; 
  scroll-behavior: smooth;
  
}


:root {
  --white: #fff;
  --text: #666666;
  --bgMain: #828282;
  --darkGray: #262626;
  --title: #2e384d;
  --lightRed: #F24535;
  --red: #D93636;
  --darkRed: #731A1A;
  --grayFooter: #222222;
  --yellowCoin: #ffbb33;
  --blueCiano: rgba(40, 185, 176, 0.73);

  /* Palheta 1 */
  --color1: #D9D9D9;
  --color2: #06070D;
  --color3: #8095BF;
  --color4: #244673;
  --color5: #BF984E;
}


html, body, #root {
  background: var(--color1);
  color: var(--color2);
}

h1, h2, h3, h4, p {
  font-family: 'Titillium Web', sans-serif;
  text-align: justify-all;
}

