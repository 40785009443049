.about {
    background: #111111;
    color: #D9D9D9;
}

.about .container {
    width: 72%;
    padding: 7em 0;
    margin: 0 auto;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.learn-more {
    font-size: 1.2em;
    color: white;
    background-color: #111111;
    border-style: none;
    text-decoration: underline;
    padding-top: 10px;
}

.learn-more:hover {
    cursor: pointer;
}

h4 {
    font-size: 2.4em;
    text-transform: uppercase;
    font-weight: 100;
}

p {
    text-justify: justify;
    font-size: 1.4em;
    font-weight: 400;
    margin: 2em 0 ;
    text-align: center;
    padding: 15px 0;
}

.office {
    min-width: 600px;
}

@media screen and (max-width: 720px) {
    
    .about .container {
        width: 100%;
    }

    .about .container h4 {
        font-size: 1.4em;
        line-height: 1.6em;
    }
    
    p {
        font-size: 1.1em;
    }

    .office {
        min-width: 290px;
    }
}