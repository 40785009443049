.div-whatsapp{
    position:fixed;
    width:60px;
    height:60px;
    bottom:20px;
    right:40px;
    background-color:#25d366;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    box-shadow: 1px 1px 2px black;
    padding-top: 11px; 
    animation: bounce .5s alternate infinite;
    margin: 60px 30px;
    cursor: pointer;
    z-index:1000;
}

.div-whatsapp:hover {
    animation: none;
}


.div-whatsapp:hover .div-text {
    display: block;
    transition: ease-in 0.7s;
}

.div-text {
    display: none;
    position: fixed;
    background-color: #FFF;
    width: 30%;
    height: 60px;
    bottom: 80px;
    right: 140px;
    border-radius: 50px;
}

.div-text p {
    font-size: 1.3em;
    font-weight: 500;
    color: black;
}

@keyframes bounce {
    from {
        transform: scaleX(1.20);
    }
    to {
        transform: translateY(-60px) scaleX(1);
    }
}

@media screen and (max-width: 450px) {
    .div-whatsapp {
        right: 20px;
        animation: bounce .5s alternate 4;
    }

    .div-whatsapp:hover .div-text {
        display: none;
    }

}