.budgets {
    background: #38435A;
    display: flex;
    flex-direction: column;
    padding: 50px 0 ;
}

.budgets .container {
    display: flex;
    flex-direction: column;
    color: white;
    width: 72;
}

.budgets .container .h4 {
    text-align: center;
    font-weight: 200;
    font-size: 2em;
}

p {
    text-align: center;
}

.contact__form { 
    display: flex;
    flex-direction: column;
}

.field {
    width: 570px;
    height: 50px;
    margin-bottom: 20px;
    border: none;
    border-bottom: 1px solid white;
    background-color: transparent;
    color: white;
    font-size: 20px;
    padding-left: 5px;
    text-shadow: 2px 2px 5px #000000;
}

.field::placeholder {
    color: white;
    opacity: 1;
    font-size: 20px;
    display: flex;
    text-align: start;
    text-shadow: 2px 2px 5px #000000;
}

.field:hover {
    border: 1px solid white;
}

.field:focus {
    border-style: none;
    border: 2px solid white; 
    outline: 0;  
}

textarea.field {
    resize: none;
    height: 120px;
    padding-top: 5px;
}

.button {
    background-color: #111111;
    color: white;
    border: 2px solid #000000;  
    font-size: 20px;
    font-weight: 600;
    height: 50px;
    width: 570px;
    margin-bottom: 50px;
}

.button:hover {
    cursor: pointer;
    background-color: #111111;
    border: 2px solid white;
    
    transition: 0.3s;
}

.button:active {
    font-size: 20px;
    font-weight: 800;
    color: #38435A;
}


.container-forms-maps {
    padding-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 280px;
    flex-wrap: wrap;
}

iframe {
    position: sticky;
    width: 570px; 
    height: 680px; 
}

@media screen and (max-width: 1800px) {
    .field {
        width: 485px;
    }    

    .button {
        width: 485px;
    }

    iframe {
        width: 485px;
    }

    .container-forms-maps {
        margin: 0 220px
    }
}



@media screen and (max-width: 1024px) {
    
    .field {
        width: 450px;
    }

    .button {
        width: 450px;
    }


    .container-forms-maps {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        margin: 0 ;
    }

    iframe {
        width: 450px; 
        height: 475px;
    } 
}

@media screen  and (max-width: 760px){
    
    .budgets .container {
        width: 100%;
    }

    .budgets .container h4 {
        font-size: 1.4em;
        line-height: 1.6em;
    }
    
    p {
        font-size: 1.1em;
        padding: 15px 0;
    }

    .field {
        width: 300px;
    }

    .button {
        width: 300px;
    }

    iframe {
        width: 300px; 
        height: 400px;
    } 
    
}