.home {
    height: 100vh;
    color: rgb(255, 255, 255);
    background: #444;
    background: url('/public/static/images/background-img.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 35rem;
    width: 100%;
}

.container {
    margin: 0 auto;
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.container h1 {
    font-size: 4em;
    font-weight: 200;
    line-height: 2em;
}

.container h4 {
    font-weight: 200;
    font-size: 2em;
    max-width: 720px;
    width: 100%;
    text-align: center;
    line-height: 2em;
    text-transform: uppercase;
}


.btn {
    padding: .5em 3em;
    transition: .5s;
    cursor: pointer;
    border: none;
    margin-top: 1em;
    font-size: 24px;
    margin-top: 2em;
    text-transform: uppercase;
    background: #111111;
}

.btn a {
    color: var(--white);
    text-decoration: none;
}

.btn:hover {
    background: #38435A;
    transition: all 0.7s ease-out;
}

.btn:active {
    background: #111111;
    transition: all 0.5s ease-out;
}

@media screen and (max-width: 426px){
    .btn {
        font-size: 20px;
        padding: .4em 2.5em;
    }
}

.container h1, .container h4 {
    text-shadow: 5px 5px 10px #000000;
}

@media screen and (max-width: 800px){
    .container h1 {
        font-size: 3em;
    }
    .container h4 {
        font-size: 1.43em;
    }
}
@media screen and (max-width: 426px){
    .container h1 {
        font-size: 2.8em;
        line-height: 2;
    }
    .container h4 {
        font-size: 1.2em;
        line-height: 1.6em;
    }
}
@media screen and (max-width: 376px){
    .container h1 {
        font-size: 2em;
        line-height: 2em;
    }
    .container h4 {
        font-size: 1em;
        line-height: 1.6em;
    }
}