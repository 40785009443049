.inner {
    margin: 0 auto;
    width: 72rem;
    max-width: calc(100% - 6rem);
}

@media screen and (max-width: 480px) {
    .inner {
        max-width: calc(100% - 3rem);
    }
}

.footer {
    background-color: #111111;
    color: rgba(255, 255, 255, 0.5);
    padding: 4rem 0 4rem 0;
}

.footer h1,
.footer h2,
.footer h3,
.footer h4,
.footer h5,
.footer h6 {
    color: #ffffff;
}

.footer a {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    transition: ease .5s;
}

.footer a:hover {
    color: #ffffff;
}

.footer p {
    text-align: start;
    margin: 0;
}

.footer .content {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    margin-bottom: 2em;
}

.footer .content section {
    width: 50%;
    padding: 0 2rem;
}

.footer .content section:first-child {
    width: 50%;
}

.footer .content section ul{
    list-style: none;
}

.footer .content section h3,
.footer .content section h4 {
    margin: 15px 0;
}

.footer .copyright {
    border-top: 1px solid;
    font-size: 0.8rem;
    opacity: 0.5;
    padding: 2rem 0;
    text-align: center;
}

@media screen and (max-width: 1280px) {
    .footer {
        padding: 4rem 0 2rem 0;
    }
}

@media screen and (max-width: 980px) {
    .footer .content {
        -moz-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .footer .content section {
        width: 50%;
    }

    .footer .content section:first-child {
        width: 100%;
        padding-right: 0;
    }
}

@media screen and (max-width: 736px) {
    .footer {
        padding: 2rem 0 ;
    }

    .footer .content section {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .footer {
        padding: 2rem 0 0.1rem 0;
    }
}